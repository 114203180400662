/* LearnPageMain.css */
.card-container {

    grid-template-columns: repeat(1fr);
    grid-gap: 16px;
  }
.article-card {
    width: 200px; 
    margin: 0 auto;
  }
.article-image {
    object-fit: cover;
    width: 100%;
    height: 240px; 
  }

/* grid-template-rows: repeat(5, 1fr); */
  