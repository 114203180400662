.content-section-three {
    position: relative;
    width: 100%;
    height: 100%;


    .image-slider {
        width: 100%;
        height: 100%;
        position: relative;

        .slide {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
            background-position: center;
            transition: opacity 0.5s ease;
            opacity: 0;
            &.active {
                opacity: 1;
            }
            .slide-content {
                top: 20%;
                position: absolute;
                padding: 2rem;

                width: 30%;
                .slide-title {
                    font-family: "Lemon", serif;
                    font-family: "Manrope", sans-serif;
                    font-family: "Oswald", sans-serif;
                    font-family: "Playfair Display", serif;
                    font-size: 3rem;
                    margin-bottom: 1rem;
                    text-align: left;
                }
                .slide-description {
                    font-size: 1rem;
                    margin-bottom: 3rem;
                    white-space: normal;
                    text-align: left;
                }
            }
        }
    }
    .slider-controls {
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
        display: flex;
        .control-button {
            background: none;
            border: none;
            cursor: pointer;
            margin: 0 10px;
            color: #fff;
            &:hover {
                color: #ccc;
            }
            .icon {
                width: 30px;
                height: 30px;
            }
        }
    }


    .slide-read-more {
        position: absolute;
        top: 70%;
        left: 10%;
        background-color: transparent;
        color: #fff;
        border: 2px solid #fff;
        margin-top: 4rem;
        padding: 1rem 2rem;
        border-radius: 25px;
        font-size: 1.2rem;
        z-index: 20;
        pointer-events: all;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }


.footer {
    position: absolute;
    left: 0;
    bottom: 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    height: 7vh;
    background: #f0f0f0;
    z-index: 7;
    padding: 0 20px;
    padding-left: 34%;

    .footer-icons {
        bottom: 0;
        display: flex;
        justify-content: center;
        flex: 1;

        .icon {
            font-size: 24px;
            color: #333;
            margin: 0 10px;

            &:hover {
                color: #007bff;
            }
        }
    }

    .footer-text {
        flex: 1;
        text-align: right;
    }
}
}