.chat {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: fixed;
  bottom: 20px;
  right: 20px;
  resize: both;
  overflow: auto;
  z-index: 99;
}

.chat-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #712196;
  color: #fff;
}

.chat-title h1,
.chat-title h2 {
  margin: 0;
}

.messages {
  flex: 1;
  overflow-y: scroll;
}

.messages-content {
  padding: 10px;
}

.message {
  display: flex;
  margin-bottom: 10px;
  position: relative;
}

.message.new .timestamp {
  opacity: 0;
}
.message.personal {
  background-color: #4a8df8;
  color: #ffffff;
  float: right;
  border-bottom-right-radius: 0;
}

.message.fake {
  background-color: #f1f1f1;
  color: #000000;
  float: left;
  border-bottom-left-radius: 0;
}

.message {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 70%;
  border-radius: 20px;
  clear: both;
}

.message > .timestamp {
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.message.fake > .timestamp {
  color: rgba(0, 0, 0, 0.5);
}
.message.new:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  animation: fadeIn 0.3s ease-in;
}

.message.loading {
  display: flex;
  align-items: center;
}

.message.loading span {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #e5e5e5;
  border-radius: 50%;
  animation: loading 0.8s linear infinite;
}

.message.loading span:nth-child(2) {
  animation-delay: 0.1s;
}

.message.loading span:nth-child(3) {
  animation-delay: 0.2s;
}

.message.loading span:nth-child(4) {
  animation-delay: 0.3s;
}

.message.loading span:nth-child(5) {
  animation-delay: 0.4s;
}

.message.loading span:nth-child(6) {
  animation-delay: 0.5s;
}

.message.loading span:nth-child(7) {
  animation-delay: 0.6s;
}

.message.loading span:nth-child(8) {
  animation-delay: 0.7s;
}

.message.loading span:nth-child(9) {
  animation-delay: 0.8s;
}

.message.loading span:nth-child(10) {
  animation-delay: 0.9s;
}

.message.loading span:nth-child(11) {
  animation-delay: 1s;
}

.message-input {
  flex: 1;
  border: none;
  width: 380px;
  padding: 10px 15px;
  border-top: 1px solid #ccc;
  resize: none;
  outline: none;
  font-size: 16px;

}

.messages-content {
  font-size: 14px;
}
.message-submit {
  background-color: #2ecc71;
  border: none;
  color: #fff;
  padding: 0 30px;
  font-size: 0.8rem;
  line-height: 30px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  outline: none;
}

.timestamp {
  margin-left: 10px;
  font-size: 0.8rem;
  color: #999;
}

.message-personal {
  justify-content: flex-end;
  align-items: flex-end;
}

.message-personal .timestamp {
  margin-left: 0;
  margin-right: 10px;
}

.message-fake {
  justify-content: flex-start;
  align-items: flex-start;
}

.message-fake .timestamp {
  margin-left: 10px;
  margin-right: 0;
}

.avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.chat-close,
.chat-minimize {
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.chat-close {
  position: absolute;
  top: 10px;
  right: 40px;
}

.chat-minimize {
  position: absolute;
  top: 10px;
  right: 100px;
}

.chat-minimized {
  height: auto;
}

.chat-minimized .messages,
.chat-minimized .message-box {
  display: none;
}
