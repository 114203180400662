.comment-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
  
      input[type="text"] {
        padding: 1rem;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        margin-bottom: 1rem;
        transition: border 0.3s;
  
        &:focus {
          border-color: #0077cc;
          outline: none;
        }
      }
  
      input[type="submit"] {
        padding: 0.75rem 1rem;
        background-color: #0077cc;
        border: none;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        transition: background 0.3s;
  
        &:hover {
          background-color: #005fa3;
        }
      }
    }
  
    &__comments {
      width: 100%;
      max-width: 500px;
      margin-top: 2rem;
  
      .comment {
        padding: 1rem;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
  
        &__details {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  