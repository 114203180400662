// WorkspacePage.scss
.workspace-page {
    background-color: #1e1e1e;
    color: #fff;
    font-family: 'Courier New', Courier, monospace;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
  
    .input-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #333;
      padding: 10px;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.5);
  
      input {
        width: calc(100% - 120px); // 버튼 너비만큼 제외
        padding: 10px;
        margin-right: 10px;
        border: none;
        border-radius: 4px;
        color: #000;
      }
  
      button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        background-color: #555;
        color: #fff;
        cursor: pointer;
  
        &:hover {
          background-color: #777;
        }
      }
    }
  }
  