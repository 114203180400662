.guide-page-wrapper {
    background-color: #111;
    /* background-image: url('path/to/cyber-background.jpg'); */
    background-size: cover;
    color: #800080;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.guide-page-content {
    max-width: 1200px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 20px;
}

.guide-page-title {
    color: #800080; /* updated */
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.guide-page-bot-section {
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid #800080;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.guide-page-bot-title {
    color: #800080;
    border-bottom: 2px solid #800080;
    padding-bottom: 5px;
}

.guide-page-bot-description {
    color: #cfcfcf;
}

.guide-page-background-text {
    font-size: 10em;
    font-family: 'Courier New', Courier, monospace;
    color: rgba(128, 0, 128, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.bot-guide-card {
    background-color: #222;
    color: #fff;
    margin-top: 2em;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
}

.bot-guide-tabs .bot-guide-nav-item .bot-guide-nav-link {
    color: #800080;
}

.bot-guide-tabs .bot-guide-nav-item .bot-guide-nav-link.active {
    color: #000;
    background-color: #800080;
}

