.container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.textarea-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.inputTextarea,
.displayTextarea,
.categorizedTextarea {
    width: 100%;
    min-height: 400px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.buttonContainer {
    min-width: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.categorizeButton {
    margin-bottom: 20px;
    width: 20%;
    margin:auto;

}
