//ContentSectionTwo.scss
.content-section-two {
  justify-content: center; // Center the content vertically
  align-items: center; // Center the content horizontally
  display: flex;
  width: 100vw;
  height: 100vh;

  position: relative;
  background-color: #000;
  z-index: 2;
}

.carousel-container {
  transform: translate(5%, 13%);
  height: 100%;
  width: 100%;
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    height: 80%;
    .software-card {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
      position: relative;
      overflow: hidden;
      z-index: 2;

      transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
      }
      &.active {
        background-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        filter: brightness(130%);
        z-index: 3;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.button-container {
  position: absolute;
  bottom: 3%; // 이미지 하단에서 10px 떨어진 위치
  background-color: #000;

  display: flex;

  button {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    padding: 5px 40px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #555;
    }

    &:disabled {
      cursor: not-allowed;
    }
    svg {
      font-size: 20px;
    }
  }
}

.software-details {
  flex: 0 0 30%;
  top: 10%;
  right: 5%;
  padding: 5%;
  height: 40%;
  border-radius: 10px;

  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: left;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: #C0C0C0;
  }
}

.software-title {
  font-family: "Oswald", sans-serif;
  font-family: "Playfair Display", serif;
  font-size: 8em;
  color: #f0f0f0;
  margin-bottom: 1em;
  text-align: center;
  position: absolute;
  top: 0%;
  width: 100%;
  padding-top: 10%;
  padding-left: 50%;

}

.software-description {
  font-family: "Oswald", sans-serif;
  font-size: 1em;
  color: #f0f0f0;
  text-align: center;
  margin-top: auto;
  animation: slideInFromLeft 2s ease-out;
}
.find-dealer-button {
  position: absolute;
  bottom: -10%;
  background-color: transparent;
  border: 1px solid #777; 
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  &:after {
    content: '';
    display: block;
    width: 90%;
    height: 2px;
    background-color: #777; // 회색 선
    position: absolute;


  }
  &:hover {
    background-color: #555;
    &:after {
      background-color: transparent;
    }
  }
}
@media (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .content-section-two {
    flex-direction: column;
    align-items: center;
  }



  .card-container {
    grid-template-columns: 1fr;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.software-description-container {
  overflow: hidden;
}
