.LearnPage {
  display: flex;
  flex-direction: column;

  height: 100vh;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.LearnPage-footer {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
}

.LearnPage-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content-container {
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
}

.rounded-tabs {
  border-radius: 15px;
  overflow: hidden;
}

.rounded-tabs > .tab {
  transition: background 0.3s ease;
}

.rounded-tabs > .tab:hover {
  background: #eee;
}

.rounded-tabs > .tab.active {
  background: #ccc;
}
