.registration-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  /* Form Styling */
  .registration-form {
    width: 400px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  .registration-form h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .registration-form-group {
    margin-bottom: 20px;
  }
  
  .registration-form-control {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: border 0.3s ease;
  }
  
  .registration-form-control:focus {
    border-color: #573b8a;
  }
  
  .registration-submit-btn {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #512c96;
    transition: background-color 0.3s ease;
  }
  
  .registration-submit-btn:hover {
    background-color: #cb77f1;
  }
/* Social Buttons */
.registration-social-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .registration-social-btn {
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .registration-social-btn:hover {
    transform: scale(1.05);
  }
.registration-google-btn {
	display: flex;
	margin: 10px auto;
	align-items: center;
	background-color: white;
	color: black;
	padding: 10px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
  }
  
  .registration-google-btn img {
	height: 20px;
	margin-right: 10px;
  }
  .registration-github-btn {
	display: flex;
	margin: 10px auto;
	align-items: center;
	background-color: white;
	color: black;
	padding: 10px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
  }
  
  .registration-github-btn img {
	height: 20px;
	margin-right: 10px;
  }
  /* Modal */
.registration-modal {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
  }
  
  .registration-modal-close-btn {
    border: none;
    background-color: #cb77f1;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .registration-modal-close-btn:hover {
    background-color: #cb77f1;
  }