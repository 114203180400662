// topMenu.scss
.custom-navbar {
  position: fixed;
  width: 100%;
  z-index: 20;
  background-color: var(--secondary-color); 
  height: 60px;


  .navbar-brand,
  .nav-link {
    margin-right: 60px;
    color: var(--text-color) !important;
    transition:
      color 0.3s ease,
      opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }


  .dropdown-menu {
    display: none;
    top: 40px;
    background-color: var(--secondary-color);
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;

    .dropdown-item {
      color: var(--text-color);
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #313131;
      }
    }
  }
  .nav-item:hover .dropdown-menu {
    display: block;
  }
}
