// MainPage.scss

:root {
  --primary-color: #f0e6ef; 
  --secondary-color: #242424;
  --accent-color: #a37eba; 
  --text-color: #e1e1e1; 
  --background-color: #121212;
  --dropdown-hover-color: #2d2c2d;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100%;
  overflow: hidden;
}


.scroll-container {
  padding-top: 60px;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.section {
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--secondary-color);
  padding: 0 15px;

  .navbar-brand, .nav-link {
    margin-right: 20px;
    color: var(--text-color) !important;
    transition: color 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .top-nav-dropdown-menu {
    top: 50px;
    background-color: var(--secondary-color);
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    .top-nav-dropdown-item {
      color: var(--text-color);
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--dropdown-hover-color); 
      }
    }
  }
}

.scroll-container {
  padding-top: 70px;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}
