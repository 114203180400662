.content-section-one {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
    .video-background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  
    .video-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
      z-index: 2;
  
      h1 {
        font-family: 'Merriweather', serif;
        font-size: 4rem;
        margin-bottom: 3rem;
      }
  
      p {
        font-size: 1.5rem;
      }
    }
  
    .media-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
      background: rgba(0, 0, 0, 0.5);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease;
      transform: translateY(80%);
      opacity: 0;
      z-index: 3;
  
      &:hover {
        transform: translateY(0);
        opacity: 1;
      }
  
      .media-section {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
  
        .media-card {
          width: 300px;
          max-width: 100%;
          margin-bottom: 15px;
  
          video, img {
            width: 100%;
            height: auto;
          }
  
          .empty {
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            color: white;
            background-color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .footer {
      position: absolute;
      left: 0;
      bottom: 0%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: right;
      height: 7vh;
      background: #f0f0f0;
      z-index: 7;
      padding: 0 20px;
      padding-left: 34%;
  
      .footer-icons {
          bottom: 0;
          display: flex;
          justify-content: center;
          flex: 1;
  
          .icon {
              font-size: 24px;
              color: #333;
              margin: 0 10px;
  
              &:hover {
                  color: #007bff;
              }
          }
      }
  
      .footer-text {
          flex: 1;
          text-align: right;
      }
  }
  }
  